<template>
  <div class="box">
    <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
      <van-list
        class="content-info"
        v-model="isUpLoading"
        :finished="upFinished"
        finished-text="没有更多了"
        @load="onLoadList"
        :immediate-check="false"
        offset="1"
      >
        <div class="content" v-for="item in list" :key="item.id">
          <van-image
            width="60"
            height="60"
            class="vantImage"
            lazy-load
            :src="item.logo"
          />
          <div class="centerInfo">
            <span class="text">{{ item.name }}</span>
            <div class="star">
              <van-rate
                :value="Number(item.score)"
                :size="10"
                color="#ffd346"
                allow-half
                void-icon="star"
                void-color="#eee"
              />
              <div>
                <span class="info">{{ item.score }}</span>
                <span class="download">下载：{{ item.downnum }}次</span>
              </div>
            </div>
            <div class="gameInfo">
              <span
                v-for="(info, index) in item.lable"
                :key="index"
                class="gameInfoText"
                >{{ info.lable }}</span
              >
            </div>
          </div>
          <div class="install">
            <img
              src="../common/img/play.png"
              class="image"
              @click="downLoad(item.downurl)"
            />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getList } from "../api/index";
export default {
  data() {
    return {
      list: [],
      page: 1,
      num: 10,
      isUpLoading: false, //上拉加载
      upFinished: false, // 上拉加载完毕
      isDownLoading: false, // 下拉刷新
    };
  },
  created() {
    this.getroadList();
  },
  methods: {
    downLoad(url) {
      window.location.href = url;
    },
    onDownRefresh() {
      this.page = 1;
      this.upFinished = false; // 不写这句会导致上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.getroadList(); // 加载数据
    },
    getroadList() {
      const _self = this;
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      let data = {
        page: _self.page,
        num: _self.num,
      };
      getList(data)
        .then((res) => {
          if (!res.status == 200) return;
          let rows = res.data.data;
          if (rows == null || rows.length === 0) {
            // 加载结束
            _self.upFinished = true;
            return;
          }
          if (rows.length < _self.num) {
            // 最后一页不足10条的情况
            _self.upFinished = true;
          }
          if (_self.page === 1) {
            _self.list = rows;
          } else {
            _self.list = _self.list.concat(rows);
          }
        })
        .catch((error) => {
          this.$toast({
            message: "获取资源列表异常{" + error + "}",
          });
        })
        .finally(() => {
          _self.isDownLoading = false;
          _self.isUpLoading = false;
        });
    },
    onLoadList() {
      this.page++;
      this.getroadList();
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100vh;
  background: url(../common/img/bgc.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-info {
  height: 13.5rem;
  width: 8.5rem;
  overflow: hidden;
  overflow-y: scroll;
}
.content {
  display: flex;
  position: relative;
  margin-bottom: 0.2rem;
  .centerInfo {
    margin-left: 0.15rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .text {
      font-size: 0.35rem;
      font-weight: bold;
      color: #333333;
    }
    .star {
      margin-top: 0.1rem;
      display: flex;
      align-items: center;
      .info {
        color: #666666;
        margin-left: 0.15rem;
        font-size: 0.15rem;
      }
      .download {
        color: #666666;
        margin-left: 0.15rem;
        font-size: 0.12rem;
      }
    }
    .gameInfo {
      margin-top: 0.15rem;
      .gameInfoText {
        font-size: 0.15rem;
        color: #999999;
        margin-right: 0.1rem;
      }
      .gameInfoText:not(:first-child) {
        border-left: 1px solid #999999;
        padding-left: 0.1rem;
      }
    }
  }
}
.install {
  display: flex;
  position: absolute;
  right: 0.15rem;
  top: 50%;
  transform: translate(0, -50%);
}
.image {
  width: 1.6rem;
  height: auto;
}
.vantImage {
  overflow: hidden;
  border-radius: 0.2rem;
  margin-left: 0.1rem;
}
</style>
